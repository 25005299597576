<template>
  <v-row class="ma-0" v-scroll="onScroll">
    <!-- welcome -->
    <v-col :cols="env === 'ax' ? 5 : 6">
      <!-- <ax-welcome /> -->
    </v-col>
    <!-- end welcome -->

    <v-spacer/>
    <!-- <v-col :class="[{'container-video' : env === 'ax'}, 'py-0 pr-8 lightGrey']" cols="6">
      <div class="container-video__bg transparent ma-0" v-if="env === 'ax'"></div>
      <v-col :class="[{'container-video__body' : env === 'ax'}, 'pt-10 mx-5']"> -->
    <v-col :cols="env === 'ax' ? 7 : 6" class="px-8 pt-0">
      <div style="min-height: 125px;">
        <div :class="env === 'ax' ? 'col-7' : 'col-6'" class="pr-16 background pt-10" style="position: fixed; z-index: 10; min-height: 100px;" :style="scroll > 100 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15);' : ''">
          <v-toolbar class="mb-2 transparent" flat height="46" min-width="100%">
            <v-toolbar-title class="text-h4 pa-0">Organizaciones</v-toolbar-title>
          </v-toolbar>
          <v-divider />
        </div>
      </div>
      <v-card class="mb-4 ml-3" flat>
        <v-toolbar flat height="46" class="pa-0 transparent">
          <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">Información de la organización</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <template v-if="env !== 'fc'">
            <v-col cols="5" class="px-5 mb-5">
              <span class="grey-700--text body-1">País</span>
              <v-autocomplete v-model="$v.newAccount.country.$model" :error="$v.newAccount.country.$error" :items="countriesList.slice(0, 2)" placeholder="Selecciona el país" single-line item-text="name" item-value="id" hide-details dense height="30" outlined />
            </v-col>
            <v-divider />
          </template>
          <v-row :class="[`px-5`, {'mt-6' : env !== 'fc'}]" no-gutters>
            <v-col cols="8" class="px-0">
              <span class="grey-700--text body-1">Nombre de la organización</span>
              <v-text-field v-model.trim="$v.newAccount.name.$model" :error="$v.newAccount.name.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <template v-if="newAccount.country">
                <span class="ml-1 grey-700--text body-1">{{env === 'fc' ? 'RUT' : translate[newAccount.country]}} </span>
                <v-text-field v-model.trim="$v.newAccount.tax_id.$model" :error="$v.newAccount.tax_id.$error" v-dni="newAccount.country" outlined required single-line dense maxlength="64" hide-details />
              </template>
            </v-col>
          </v-row>
          <v-row class="px-5" no-gutters>
            <v-col cols="5" class="pa-0 my-4">
              <span class="ml-1 grey-700--text body-1">Comuna</span>
              <v-autocomplete v-model="$v.newAccount.state.$model" :error="$v.newAccount.state.$error" :items="stateList" single-line item-text="name" item-value="id" hide-details dense height="30" outlined :return-object="false" />
            </v-col>
            <v-col class="pa-0 ml-3 my-4">
              <span class="ml-1 grey-700--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.newAccount.address.$model" :error="$v.newAccount.address.$error" outlined required single-line dense maxlength="128" hide-details />
            </v-col>
          </v-row>
          <v-row class="py-0 px-5" no-gutters>
            <v-col cols="6" class="pa-0">
              <span class="ml-1 grey-700--text body-1">Email de contacto</span>
              <v-text-field v-model.trim="$v.newAccount.email.$model" :error="$v.newAccount.email.$error" outlined required single-line dense maxlength="56" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3" v-if="newAccount.country">
              <span class="ml-1 grey-700--text body-1">Teléfono</span>
              <vue-tel-input id="vue-tel-input-custom" v-model.trim="$v.newAccount.phone.$model" :error="$v.newAccount.phone.$error" v-bind="bindProps" @country-changed="changeCountry" :key="reset" />
            </v-col>
          </v-row>
          <v-col cols="12" class="px-5 pt-0 my-5">
            <span class="ml-1 grey-700--text body-1">Sitio web</span>
            <v-text-field v-model.trim="$v.newAccount.website.$model" :error="$v.newAccount.website.$error" outlined required single-line dense maxlength="64" hide-details />
          </v-col>
          <v-divider />
          <v-toolbar-title class="px-5 mt-6 subtitle-2 grey-700--text font-weight-bold">Información del receptor</v-toolbar-title>
          <v-row class="mt-6 px-5 pb-3" no-gutters>
            <v-col cols="5" class="px-0">
              <span class="grey-700--text body-1">Nombre</span>
              <v-text-field v-model.trim="name" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col cols="5" class="pt-0 pl-3">
              <span class="ml-1 grey-700--text body-1">Email</span>
              <v-text-field v-model.trim="email" outlined required single-line dense maxlength="56" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-4">
          <v-spacer />
          <v-btn :to="{ name: 'AuthChoose' }" outlined>Cancelar</v-btn>
          <v-btn @click="createAccount()" :loading="$store.state.accounts.createLoader" color="blue-500">Crear organización</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { email, minLength, maxLength, required, url} from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    scroll: 0,
    reset: 0,
    email: '', // pending
    name: '', // pending
    newAccount: {
      address: '',
      country: '',
      email: '',
      name: '',
      phone: '',
      state: '',
      tax_id: '',
      website: ''
    },
    bindProps: {
      mode: 'international',
      defaultCountry: '',
      disabledFetchingCountry: false,
      disabled: false,
      disabledFormatting: false,
      placeholder: 'Teléfono',
      required: false,
      enabledCountryCode: true,
      enabledFlags: true,
      preferredCountries: ['CL', 'PE'],
      onlyCountries: [],
      ignoredCountries: [],
      autocomplete: 'off',
      name: "telephone",
      maxLen: 25,
      wrapperClasses: "",
      inputClasses: "",
      validCharactersOnly: true,
      inputOptions: {
        showDialCode: false
      }
    },
    env: process.env?.VUE_APP_MODE ?? 'ax',
    translate: {
      CL: 'RUT',
      PE: 'RUC'
    },
    isCreate: false
  }),
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList,
      trace: state => state.auth.trace
    }),
    stateList () {
      return this.countriesList.find(({id}) => id === this.newAccount.country)?.states ?? this.countriesList[0].states
    }
  },
  watch: {
    'newAccount.country': {
      handler (val) {
        this.reset += 1
        this.newAccount.phone = ''
        this.bindProps.defaultCountry = val
        this.newAccount.tax_id = ''
        this.newAccount.state = this.countriesList?.find(({id}) => id === val).states[0].id
      },
      inmediate: true,
      deep: true
    }
  },
  created () {
    if (this.env === 'fc') this.newAccount.country = 'CL'
    else if (this.newAccount.country) {
      // const { id, states } = this.countriesList?.find(({id}) => id === this.newAccount.country.id)
      // this.newAccount.country = this.newAccount.country
      // this.newAccount.state = states[0].id
      // this.bindProps.defaultCountry = this.newAccount.country
    }
    // this.$store.dispatch('auth/RETRIEVE_TRACE')
    // .then(() => {
    //   this.newAccount.country = this.trace.loc
    // })
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    changeCountry (e) {
      this.newAccount.phone = ''
      this.newAccount.phone = `+${e.dialCode}${this.newAccount.phone}`
    },
    createAccount () {
      this.$v.newAccount.$touch()

      if (this.$v.newAccount.$invalid) {
        return false
      }

      this.$store.dispatch('accounts/CREATE', {
        resource: 'accounts',
        payload: this.newAccount
      })
      .then((response) => {
        const account = response.data

        // TODO replace with Zendesk script
        // track Segment group
        if (process.env.NODE_ENV === 'production') {
          window.analytics.group(account.id, {
            name: account.name,
            // chargeScheme: account.charge_scheme,
            // finishTrial: account.finish_trial,
            createdAt: account.created
          })
        }

        this.$store.commit('auth/SET_ACCOUNT', {
          account: account,
          redirect: true
        })
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.tax_id[0] || 'Error al crear la cuenta')
      })
    }
  },
  validations: {
    newAccount: {
      address: {
        required,
        maxLength: maxLength(128)
      },
      country: {
        required
      },
      email: {
        email,
        maxLength: maxLength(254)
      },
      name: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(64)
      },
      phone: {
        maxLength: maxLength(16)
      },
      state: {
        required
      },
      tax_id: {
        required,
        format: function (val) {
          if (this.newAccount.country === 'CL') {
            return rutValidator(val)
          }

          return true
        },
        maxLength: maxLength(32)
      },
      website: {
        url
      }
    }
  }
}
</script>